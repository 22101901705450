import React from 'react'

const timers = [
  {
    dStartedAt: Date.now() - 100000,
    length: 10000,
  },
]

export default () => {
  return <div>TIMER</div>
}
